import React, { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import { withTranslation } from "react-i18next";
import Dropdown from "react-dropdown";
import IconInput from "../ui/icon-input/icon-input";
import KnoxDevicesHelper from "../../utils/helpers/knox-devices-helper";
import PaginationTranslated from "../ui/pagination/pagination";
import ToastHelper from "../../utils/helpers/toast-helper";
import KnoxDevicesService from "../../service/knox_devices-service";
import { Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';


function KnoxDevicesTable(props) {
  const { t } = props;

  /* PAGINATION VALUES */
  const itemsOption = ["3", "5", "10", "20"];
  const [loading, setLoading] = useState("")
  const [items, setItems] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(itemsOption[1])
  const [totalNumber, setTotalNumber] = useState(0)
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  /* FILTER BY USER ID VALUES */
  const [deviceUserIdFilter, setDeviceUserIdFilter] = useState("")

  /* FILTER BY IMEI VALUES */
  const [deviceIMEIFilter, setDeviceIMEIFilter] = useState("")

  /* FILTER BY STATE VALUES */
  const [stateFilter, setStateFilter] = useState('ALL')

  function searchByUserId(event) {
    event.preventDefault()

    if (deviceUserIdFilter !== "") {
      ToastHelper.showLoading(KnoxDevicesService.findByUserId(currentPage, itemsPerPage, 'DESC', deviceUserIdFilter), {
        error: {
          notFound: 'Not found'
        },
      })
        .then(response => {
          setItems(response.data.content);
          setTotalNumber(response.data.totalElements);
        })
        .catch(e => {
          console.log(e)
          setItems(null)
        });
    } else {
      // clear the filter
    }
  }

  function searchByIMEI(event) {
    event.preventDefault()

    if (deviceIMEIFilter !== "") {
      ToastHelper.showLoading(KnoxDevicesService.findByIMEI(currentPage, itemsPerPage, 'DESC', deviceIMEIFilter), {
        error: {
          notFound: 'Not found'
        },
      })
        .then(response => {
          setItems(response.data.content);
          setTotalNumber(response.data.totalElements);
        })
        .catch(e => {
          console.log(e)
          setItems(null)
        });
    } else {
      // clear the filter
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let result;

        if (stateFilter === 'ALL' && deviceUserIdFilter === "") {
          result = await KnoxDevicesService.findAllDevices(currentPage, itemsPerPage, 'DESC');
        } else {
          result = await KnoxDevicesService.findByState(currentPage, itemsPerPage, 'DESC', stateFilter);
        }

        setItems(result.data.content);
        setTotalNumber(result.data.totalElements);
        setLoading("");
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [currentPage, itemsPerPage, stateFilter, deviceUserIdFilter]);


  return (
    <>

      <section
        style={{ display: 'flex', gap: '20px' }}
        className={showModal ? 'blurred transition' : 'transition'}
      >
        {/* Icons and filters */}
        <IconInput
          type="text"
          placeholder={t('knox_devices.imei')}
          icon="bi bi-search"
          onClick={(event) => searchByIMEI(event)}
          onChange={(event) => setDeviceIMEIFilter(event.target.value)}
        />
        <IconInput
          type="text"
          placeholder={t('knox_devices.user_id')}
          icon="bi bi-search"
          onClick={(event) => searchByUserId(event)}
          onChange={(event) => setDeviceUserIdFilter(event.target.value)}
        />

        {/* Filter by state */}
        <div style={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
          <p>{t('knox_devices.state')}</p>
          <Dropdown className="form-select dropdown-large"
            options={KnoxDevicesHelper.deviceStates()}
            onChange={(event) => setStateFilter(event.value)}
            value={stateFilter}
            placeholder={t('knox_devices.state_all')} />
        </div>

        <Dropdown className="form-select" options={itemsOption} onChange={(event) => setItemsPerPage(event.value)}
          value={itemsPerPage} />
      </section>

      {/* Table */}
      <div className={showModal ? 'blurred transition' : 'transition'}>
        <table className="custom-table">
          <thead>
          <tr>
            <td>{t('knox_devices.created_at')}</td>
            <td>{t('knox_devices.imei')}</td>
            <td>{t('knox_devices.user_id')}</td>
            <td>{t('knox_devices.state')}</td>
            <td></td>
          </tr>
          </thead>
          <tbody className="custom-tbody">
          {items.map(item => (
            <tr key={item.id}>
              <td>{KnoxDevicesHelper.createdAt(item) || 'N/A'}</td>
              <td>{KnoxDevicesHelper.imei(item, true) || 'N/A'}</td>
              <td>{KnoxDevicesHelper.userId(item) || 'N/A'}</td>
              <td>{KnoxDevicesHelper.stateDescription(item) || 'N/A'}</td>
              <td>
                {KnoxDevicesHelper.stateDescription(item) !== 'Deleted' && (
                  <i
                    className="bi bi-trash3-fill"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedItem(item);
                      setShowModal(true);
                    }}
                  ></i>
                )}
              </td>
            </tr>
          ))}
          </tbody>

        </table>
      </div>

      <PaginationTranslated
        totalNumber={totalNumber}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        setLoading={setLoading}
      />

      {/* Pop-up */}
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        dialogClassName="custom-modal"
      >
        <div className="form-knox">
          <Modal.Body>
            <p>{t('knox_devices.delete_imei')}{selectedItem?.imei}</p>
          </Modal.Body>
          <Modal.Footer className="icon-container">
            <button
              className="form-button"
              onClick={() => {
                setIsProcessing(true); // Activate loading
                KnoxDevicesService.releaseDevice(selectedItem)
                  .then(() => {
                    toast.success(t('knox_devices.excluded_item'));
                    setShowModal(false);
                    setIsProcessing(false); // Disable loading
                  })
                  .catch((error) => {
                    console.error("Error deleting IMEI:", selectedItem);
                    toast.error(t('knox_devices.error_excluded'));
                    setShowModal(false);
                    setIsProcessing(false); // Disable loading
                  });
              }}
            >
              <p>{t('knox_devices.confirm')}</p>
            </button>
            <button className="form-button" onClick={() => setShowModal(false)}>
              <p>{t('knox_devices.cancel')}</p>
            </button>
          </Modal.Footer>
        </div>
      </Modal>

      {/* Pop-up de Loading */}
      {isProcessing && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="primary" />
          <p>{t('knox_devices.processing')}</p>
        </div>
      )}
    </>
  );
}

const KnoxDevicesTableTranslated = withTranslation()(KnoxDevicesTable)
export default KnoxDevicesTableTranslated
