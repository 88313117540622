import ProductConfigHelper from "./product-config-helper";

const PartnerHelper = {
  partnerName: (props) => props?.partner?.partnerName ?? null,
  partnerEmail: (props) => props?.partner?.partnerEmail ?? null,
  appLogo: (props) => props?.partner?.customInterface?.appLogo ?? null,
  appIconMdpiUrl: (props) => props?.partner?.customInterface?.app_icon?.['mipmap-mdpi'] ?? null,
  appIconHdpiUrl: (props) => props?.partner?.customInterface?.app_icon?.['mipmap-hdpi'] ?? null,
  appIconXhdpiUrl: (props) => props?.partner?.customInterface?.app_icon?.['mipmap-xhdpi'] ?? null,
  appIconXxhdpiUrl: (props) => props?.partner?.customInterface?.app_icon?.['mipmap-xxxhdpi'] ?? null,
  appIconXxxhdpiUrl: (props) => props?.partner?.customInterface?.app_icon?.['mipmap-xxhdpi'] ?? null,
  logo: (props) => props?.partner?.customInterface?.logo ?? null,
  backgroundColor: (props) => props?.partner?.customInterface?.backgroundColor ?? null,
  phoneNumber: (props) => props?.partner?.customInterface?.phoneNumber ?? null,
  helpPage: (props) => props?.partner?.customInterface?.help_page ?? null,
  lockMessage: (props) => props?.partner?.lockOptions?.lockMessage ?? null,
  lockType: (props) => props?.partner?.lockOptions?.lockType ?? ProductConfigHelper.lockTypes()[0].value,
  webhook: (props) => props?.partner?.customInterface?.webhook ?? null,
  webhookUrlCustomer: (props) => props?.partner?.customInterface?.webhookUrlCustomer ?? null,
  hasBiometric: (props) => props?.partner?.customInterface?.hasBiometric === "true" ?? false,
  preCreateCollateral: (props) => props?.partner?.customInterface?.preCreateCollateral === "true" ?? false,
  preLocked: (props) => props?.partner?.lockOptions?.preLocked === "true" ?? false,
  configurationKG: (props) => props?.partner?.customInterface?.configurationKG ?? null,
  appConfiguration: (props) => props?.partner?.customInterface?.appConfiguration ?? null,
  partnerCode: (props) => props?.partner?.productCode,
  ads: (props) => props?.partner?.ads ?? {},
  version: (props) => props?.partner?.version ?? {},
  temporaryUnlock: (props) => props?.partner?.lockOptions.temporaryUnlock ?? {},
  knoxGuard: (props) => props?.partner?.lockOptions.knoxGuard ?? {},
  push: (props) => props?.partner?.lockOptions.push ?? {},
};

export default PartnerHelper;
