import React from 'react';
import { withTranslation } from "react-i18next";
import { Tooltip } from '@material-ui/core';

class HasBiometricChangeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasBiometric: props.value,
      hasBiometricAux: props.value,
      showHasBiometric: (props.value != null && props.value)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col" style={{ marginBottom: "30px" }} onClick={this.handleHasBiometricChange}>
          <label>{t('custom.biometric_label')}</label>
          <div>
            {this.state.showHasBiometric ? (
                <i className="bi bi-toggle-on" />
              ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.biometric_tooltip')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>
      </div>
    );
  }

  handleHasBiometricChange = () => {

    let isChecked = !this.state.showHasBiometric
    this.setState({ showHasBiometric: isChecked, hasBiometric: isChecked,  hasBiometricAux: isChecked });
    this.props.handle(isChecked)
  }
}

const HasBiometricChangeFormTranslated = withTranslation()(HasBiometricChangeForm)

export default HasBiometricChangeFormTranslated
