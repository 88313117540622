import React from 'react';
import { withTranslation } from "react-i18next";
import { Tooltip } from '@material-ui/core';

class PreLockedChangeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preLocked: props.value,
      preLockedAux: props.value,
      showPreLocked: (props.value != null && props.value)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col" style={{ marginBottom: "30px" }} onClick={this.handlePreLockedChange}>
          <label>{t('custom.pre_lock_label')}</label>
          <div>
            {this.state.showPreLocked ? (
                <i className="bi bi-toggle-on" />
              ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.pre_lock_tooltip')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>
      </div>
    );
  }

  handlePreLockedChange = () => {

    let isChecked = !this.state.showPreLocked
    this.setState({ showPreLocked: isChecked, preLocked: isChecked,  preLockedAux: isChecked });
    this.props.handle(isChecked)
  }
}

const PreLockedChangeFormTranslated = withTranslation()(PreLockedChangeForm)

export default PreLockedChangeFormTranslated
