import React, { useState } from 'react';
import NextButton from "../ui/next-button";
import PreviousButton from "../ui/previus-button";
import AppAdvertisingForm from "../../partner/app-advertising-form";
import { getI18n } from "react-i18next";

function AppAdvertising({ step, setStep, updateFormData, formData }) {
  const I18n = getI18n()

  function handleSoftLockAdsChange(ad) {
    ad.enabled = adsIsEnabled(ad)
    formData.ads.softLock = ad
    updateFormData("ads", formData.ads)
  }

  function handleHardLockAdsChange(ad) {
    ad.enabled = adsIsEnabled(ad)
    formData.ads.hardLock = ad
    updateFormData("ads", formData.ads)
  }

  function handleKnoxGuardLockAdsChange(ad) {
    ad.enabled = adsIsEnabled(ad)
    formData.ads.knoxGuardLock = ad
    updateFormData("ads", formData.ads)

    console.log(formData.ads)

  }

  function adsIsEnabled(ad) {
    if (ad?.enabled) {
      if(ad?.source == 'GLOBETEK') {
        let hasImages = ad?.images?.some((item) => item.trim() !== "")
        let hasVideos = ad?.videos?.some((item) => item.trim() !== "")
        return (hasImages || hasVideos)
      } else {
        return (ad?.adUnitId?.length > 0 && ad?.source != null);
      }
    }
    else return false;
  }

  return (
    <div className="form-step">
      <section className="step-content-app-visual" style={{ display: "flex", gap: "90px" }}>
        <div style={{ width: '450px' }}>

          {/* SOFT LOCK */}
          <AppAdvertisingForm
            title='Soft Lock'
            ad={formData.ads?.softLock}
            handle={handleSoftLockAdsChange}
          />

          {/* HARD LOCK */}
          <AppAdvertisingForm
            title='Hard Lock'
            ad={formData.ads?.hardLock}
            handle={handleHardLockAdsChange}
          />

          {/* KNOX GUARD LOCK */}
          <AppAdvertisingForm
            title='Knox Guard Lock'
            ad={formData.ads?.knoxGuardLock}
            handle={handleKnoxGuardLockAdsChange}
          />

        </div>
      </section>
      <section className="step-controls">
        <PreviousButton step={step} setStep={setStep} />
        <NextButton step={step} setStep={setStep} />
      </section>
    </div>
  );
}

export default AppAdvertising;
