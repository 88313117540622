import React from 'react';
import { withTranslation } from "react-i18next";

class AppKnoxGuardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      knoxGuard: props.value,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col">

          <div style={{ width: "50%" }}>
            <label>Account ID</label>
            <input
              type="text"
              className="form-input"
              value={this.state.knoxGuard?.id}
              placeholder="Account ID"
              onChange={this.handleKnoxGuardIdChange}
            />
          </div>

          <div style={{ width: "50%" }}>
            <label>Customer ID</label>
            <input
              type="text"
              className="form-input"
              value={this.state.knoxGuard?.customerId}
              placeholder="Customer ID"
              onChange={this.handleKnoxGuardCustomerIdChange}
            />
          </div>
        </div>
      </div>
    );
  }

  handleKnoxGuardIdChange = (event) => {

    let obj = this.state.knoxGuard
    obj.id = event.target.value

    this.setState({
      knoxGuard: obj
    });
    this.props.handle(obj)
  };

  handleKnoxGuardCustomerIdChange = (event) => {

    let obj = this.state.knoxGuard
    obj.customerId = event.target.value

    this.setState({
      knoxGuard: obj
    });
    this.props.handle(obj)
  };
}

const AppKnoxGuardFormTranslated = withTranslation()(AppKnoxGuardForm)

export default AppKnoxGuardFormTranslated
