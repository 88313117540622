import React from 'react';
import { withTranslation } from "react-i18next";
import { Tooltip } from '@material-ui/core';

class PreCreateCollateralChangeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preCreateCollateral: props.value,
      preCreateCollateralAux: props.value,
      showPreCreateCollateral: (props.value != null && props.value)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <div className="two-col" style={{ marginBottom: "30px" }} onClick={this.handlePreCreateCollateralChange}>
          <label>{t('custom.pre_create_collateral_label')}</label>
          <div>
            {this.state.showPreCreateCollateral ? (
                <i className="bi bi-toggle-on" />
              ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.pre_create_collateral_tooltip')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>
      </div>
    );
  }

  handlePreCreateCollateralChange = () => {

    let isChecked = !this.state.showPreCreateCollateral
    this.setState({ showPreCreateCollateral: isChecked, preCreateCollateral: isChecked,  preCreateCollateralAux: isChecked });
    this.props.handle(isChecked)
  }
}

const PreCreateCollateralChangeFormTranslated = withTranslation()(PreCreateCollateralChangeForm)

export default PreCreateCollateralChangeFormTranslated
