import { getI18n } from "react-i18next";
import DateHelper from "./date-helper";
import CurrentStep from "../../components/utils/enum/current-step";
import Cookies from 'js-cookie';

const ProductConfigHelper = {

  lockTypes: () => {
    return [
      { value: 'FULL', label: getI18n().t('manage.lock_type_full') },
      { value: 'PARTIAL', label: getI18n().t('manage.lock_type_partial') },
      { value: 'PARTIAL_ADS', label: getI18n().t('manage.ads_display') }
    ];
  },

  KGSeachLabelTypes: () => {
    return [
      { value: 'IMEI', label: getI18n().t('knox_devices.imei') },
      { value: 'USER_ID', label: getI18n().t('knox_devices.user_id') }
    ];
  },

  appConfingurationFieldTypes: () => {
    return [
      { value: 'NUMBER', label: getI18n().t('custom.app_configuration_field_type_number_label') },
      { value: 'TEXT', label: getI18n().t('custom.app_configuration_field_type_text_label') },
      { value: 'EMAIL', label: getI18n().t('custom.app_configuration_field_type_email_label') },
      { value: 'PHONE', label: getI18n().t('custom.app_configuration_field_type_phone_label') }
    ];
  },

  appConfingurationFieldIsValid: (config) => {
    return config != null && config.label != null && config.label.length > 0;
  },

  confingurationKGFieldIsValid: (config) => {
    return config != null && config.inputType != null && config.inputType.length > 0;
  },

  createdAt(item, full) {
    return item
      && item.createdAt
      && full ? DateHelper.getFormattedDateTime(item.createdAt)
      : DateHelper.getFormattedDate(item.createdAt);
  },

  partnerName(item) {
    return item
      && item.partnerName;
  },

  step(item) {
    return item
      && item.step
      && CurrentStep[item.step];
  },

  isWaitingForApproval(item) {
    return item
      && item.step
      && CurrentStep[item.step]
      && CurrentStep[item.step] === CurrentStep.WAITING_FOR_APPROVAL;
  },

  productCode(item) {
    return item
      && item.productCode;
  },

  getProductCode() {
    return Cookies.get('productCode');
  },

  unlockTimes: () => {
    return [
      { value: '1', label: '1x' },
      { value: '3', label: '3x' },
      { value: '5', label: '5x' },
      { value: '10', label: '10x' },
      { value: '20', label: '20x' }
    ];
  },

  unlockDurations: () => {
    return [
      { value: '5', label: `5 ${getI18n().t('custom.minutes')}` },
      { value: '10', label: `10 ${getI18n().t('custom.minutes')}` },
      { value: '30', label: `30 ${getI18n().t('custom.minutes')}` },
      { value: '60', label: `1 ${getI18n().t('custom.hour')}` },
      { value: '120', label: `2 ${getI18n().t('custom.hours')}` },
    ];
  },

  adSources: () => {
    return [
      { value: 'GOOGLE', label: 'Google' },
      { value: 'START_IO', label: 'Start IO' },
      { value: 'GLOBETEK', label: "Globetek" }
    ];
  },
}

export default ProductConfigHelper;
