import React from 'react';
import { withTranslation } from "react-i18next";
import { Tooltip } from '@material-ui/core';

class AppWebhookKGForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webhookUrlCustomer: props.value,
      webhookUrlCustomerAux: props.value,
      showWebhookKG: (props.value != null && props.value.length > 0)
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container"  style={{ marginTop: "40px" }}>
        <label>Knox Guard</label>
        <div className="two-col" style={{ marginTop: "40px" }} onClick={this.handleShowWebhookChange}>
          <label>{t('custom.webhook_kg_label')}</label>
          <div>
            {this.state.showWebhookKG ? (
                <i className="bi bi-toggle-on" />
              ) :
              <i className="bi bi-toggle-off" />
            }
          </div>
          <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.webhook_kg_tooltip')} placement="top">
            <i className="bi bi-info-circle-fill"></i>
          </Tooltip>
        </div>

        {this.state.showWebhookKG ? (

            <input
              type="text"
              className="form-input"
              value={this.state.webhookUrlCustomer}
              placeholder={t('custom.webhook_place_holder')}
              onChange={this.handleWebhookChange}
            />

          ) :
          <div style={{ marginTop: '30px', marginBottom: '30px' }} />
        }
      </div>
    );
  }

  handleWebhookChange = (event) => {
    this.setState({
      webhookUrlCustomer: event.target.value,
      webhookUrlCustomerAux: event.target.value,
    });
    this.props.handle(event.target.value)
  };

  handleShowWebhookChange = () => {

    let isChecked = !this.state.showWebhookKG

    this.setState({ showWebhookKG: isChecked });

    if(isChecked) {
      this.setState({ webhookUrlCustomer: this.state.webhookUrlCustomerAux });
      this.props.handle(this.state.webhookUrlCustomerAux)
    } else {
      this.setState({ webhookUrlCustomer: "" });
      this.props.handle("")
    }
  }
}

const AppWebhookKGFormTranslated = withTranslation()(AppWebhookKGForm)

export default AppWebhookKGFormTranslated
