import React from 'react';
import {withTranslation} from "react-i18next";
import LoadingFullscreen from "../../components/ui/loading/loading-fullscreen";
import MultipartForm from "../../components/ui/form/multipart/multipart-form";
import ProductConfigHelper from "../../utils/helpers/product-config-helper";
import PartnerActionHelper from "../../utils/helpers/partner-action-helper";

class NewPartner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      /* Basic information */
      name: null,
      email: null,
      activationFee: 0,
      maintenanceFee: 0,

      /* App visual */
      appIcon: null,
      logo: null,
      backgroundColor: "#000000",

      /* App content */
      phoneNumber: null,
      helpPage: null,
      lockMessage: null,

      /* Advanced settings */
      lockType: ProductConfigHelper.lockTypes()[0].value,
      webhook: null,
      webhookUrlCustomer: null,
      appConfiguration: null,
      preLocked: false,
      preCreateCollateral: false,
      hasBiometric: false,
      configurationKG: null,

      action: PartnerActionHelper.CREATE,
    };
  }

  updateFormData = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {t} = this.props;
    const {loadingPage} = this.state;
    return loadingPage ?
      <LoadingFullscreen/>
      :
      <div className="demo-container">
        <p className="page-title">New partner</p>
        <MultipartForm
          props={this.props}
          updateFormData={this.updateFormData}
          formData={this.state}
        />
      </div>
  }
}

const NewPartnerTranslated = withTranslation()(NewPartner)

export default NewPartnerTranslated
