import React from 'react';
import { withTranslation } from "react-i18next";
import ProductConfigHelper from "../../../../utils/helpers/product-config-helper";
import Dropdown from 'react-dropdown';
import { Tooltip } from '@material-ui/core';

class KGSearchLabelAppForm extends React.Component {
  constructor(props) {
    super(props);

    let kgSearchLabel  = {
      inputType: props.value?.inputType || ""
    }

    this.state = {
      configurationKG: kgSearchLabel,
      configurationKGAux: kgSearchLabel
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div className="input-container">
        <label>{t('custom.search_in_app_label')} </label>
        <Tooltip style={{ backgroundColor: 'transparent' }} title={t('custom.search_in_app_tooltip')} placement="top">
          <i className="bi bi-info-circle-fill"></i>
        </Tooltip>
        <Dropdown
          className="form-select-without-margin-horizontal"
          options={ProductConfigHelper.KGSeachLabelTypes()}
          onChange={this.handleKGSearchLabelAppFormChange}
          value={this.state.configurationKG.inputType}
          placeholder={t('custom.search_in_app_label')} />

      </div>
    );
  }

  handleKGSearchLabelAppFormChange = (event) => {

    this.setState({
      kgSearchLabel: event.value,
    });

    let config = this.state.configurationKG
    config.inputType = event.value

    this.setState({
      configurationKG: config,
      configurationKGAux: config,
    });

    this.props.handle(config)
  };
}

const KGSearchLabelAppFormTranslated = withTranslation()(KGSearchLabelAppForm)

export default KGSearchLabelAppFormTranslated
